import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import Fade from "react-reveal/Fade";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import fetchProjectContent from '../AirtableDataProject';

Modal.setAppElement("#root");

const News = () => {
  const [isSliderScrolling, setIsSliderScrolling] = useState(false);
  const settings = {
    dots: true,
    arrow: false,
    infinite: true,
    speed: 900,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: false,
    margin: 20,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [modalId, setModalId] = useState(false);
  const handleClose = () => setModalId("");
  const [projectContent, setProjectContent] = useState([]);
  useEffect(() => {
    async function getProjectContent() {
      const content = await fetchProjectContent();
      setProjectContent(content);
    }
    getProjectContent();
  }, []);

  return (
    <>
      <div className="row testimonial-wrapper">
      <Slider 
        {...settings} 
        beforeChange={() => setIsSliderScrolling(true)}
        afterChange={() => setIsSliderScrolling(false)}>
        {projectContent.map((val, i) => (
          <div key={val.projectID} className="col-md-6 m-15px-tb">
            <div className="blog-grid" onClick={() => !isSliderScrolling && setModalId(`modal${i}`)}>
              <div className="blog-img">
                <Fade>
                  <img src={val.imageUrl[0].url} alt="blog post"></img>
                </Fade>
              </div>
              <div className="blog-info">
                <Fade bottom>
                  <div className="meta">{val.date}</div>
                  <h6>
                    <a href="#">
                      {val.title}
                    </a>
                  </h6>
                </Fade>
              </div>
            </div>
          </div>
        ))}
        {/* End .col for blog-1 */}
      </Slider>
      </div>
      {/* End .row */}

      {projectContent.map((val, i) => (
        <Modal
          key={val.projectID}
          isOpen={modalId === `modal${i}`}
          onRequestClose={handleClose}
          contentLabel="My dialog"
          className="custom-modal"
          overlayClassName="custom-overlay"
          closeTimeoutMS={500}
        >
          <div>
            <button className="close-modal" onClick={handleClose}>
              <img src="/img/cancel.svg" alt="close icon" />
            </button>
            {/* End close icon */}

            <div className="box_inner">
              <div className="scrollable">
                <div className="blog-grid">
                  <div className="blog-img">
                    <img src={val.imageUrl[0].url} alt="blog post"></img>
                  </div>
                  {/* End blog-img */}
                  <article className="article">
                    <div className="article-title">
                      <h2>
                        {val.title}
                      </h2>
                      <div className="media">
                        <div className="media-body">
                          <label>Suman Raj Sharma</label>
                          <span>{val.date}</span>
                        </div>
                      </div>
                    </div>
                    {/* End .article-title */}

                    <div className="article-content" dangerouslySetInnerHTML={{ __html: val.description }}>
                    </div>
                    {/* End article content */}
                    <ul className="nav tag-cloud">
                      {val.tags.map((tag, i) => (
                        <li key={i} href="#">{tag}</li>
                      ))}
                    </ul>
                    {/* End tag */}
                  </article>
                  {/* End Article */}
                  {val.projectUrl !== '' ? <a className="px-btn px-btn-white" href={val.projectUrl} target="_blank" rel="noreferrer">Project Webpage</a> : null}
                </div>
              </div>
            </div>
          </div>
          {/* End modal box news */}
        </Modal>
      ))}
    </>
  );
};

export default News;
