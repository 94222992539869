import React, { useState, useEffect } from "react";
import Fade from "react-reveal/Fade";
import fetchExperienceContent from '../AirtableDataExperience';
import fetchEducationContent from '../AirtableDataEducation';

const Resume = () => {
  const [experienceContent, setExperienceContent] = useState([]);
  const [educationContent, setEducationContent] = useState([]);

  useEffect(() => {
    async function getExperienceContent() {
      const content = await fetchExperienceContent();
      setExperienceContent(content);
    }
    async function getEducationContent() {
      const content = await fetchEducationContent();
      setEducationContent(content);
    }

    getExperienceContent();
    getEducationContent();
  }, []);
  return (
    <>
      <section id="resume" className="section">
        <div className="container">
          <div className="title">
            <h3>Experience.</h3>
          </div>
          {/* End title */}
          <div className="resume-box">
            {experienceContent.map((val, i) => (
              <Fade bottom key={i}>
                <div className="resume-row">
                  <div className="row">
                    <div className="col-md-4 col-xl-3">
                      <div className="rb-left">
                        <h6>{val.jobPosition}</h6>
                        <label>{val.jobType}</label>
                        <p>{val.jobDuration}</p>
                        <div className="rb-time">{val.timeDuration}</div>
                      </div>
                    </div>
                    <div className="col-md-8 col-xl-9">
                      <div className="rb-right">
                        <h6>{val.companyName}</h6>
                        <p>{val.jobDescription}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Fade>
            ))}
          </div>

          {/* separated */}
          <div
            className="separated"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "img/border-dark.png"
              })`,
            }}
          ></div>
          {/* End separated */}

          <div className="title">
            <h3>{"Education."}</h3>
          </div>

          <div className="resume-box">
            {educationContent.map((val, i) => (
              <Fade bottom key={i}>
                <div className="resume-row">
                  <div className="row">
                    <div className="col-md-4 col-xl-3">
                      <div className="rb-left">
                        <div className="institute_avatar center">
                          <img
                            src={`${val.imageUrl}`}
                            alt={`${val.imageName}`}
                          ></img>
                        </div>
                        {/* <div className="rb-time">{val.passingYear}</div> */}
                      </div>
                    </div>
                    <div className="col-md-8 col-xl-9">
                      <div className="rb-right">
                        <h6>{val.degreeTitle}</h6>
                        <p>{val.instituteName}</p>
                        <label>{val.passingYear}</label>
                        <p>Major(s): {val.majors}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Fade>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Resume;
