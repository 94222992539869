import Airtable from 'airtable';
require('dotenv').config()

const base = new Airtable({ apiKey: process.env.REACT_APP_AIRTABLE_TOKEN }).base('appohOKjwK2KPwIQZ');

async function fetchPersonalContent() {
  const personalContent = [];

  await base('personaldetails')
    .select({
      // Add your filters and sorting options here
      sort: [{ field: 'detailID', direction: 'asc' }],
    })
    .eachPage(
      function page(records, fetchNextPage) {
        // This function (`page`) will get called for each page of records.
        records.forEach(function (record) {
          // Map the Airtable record to the required format
          const name = record.get('name');
          const quote = record.get('quote');
          const status = record.get('status');
          const resume = record.get('resume');
          const btnText = record.get('btnText');
          const phone = record.get('phone');
          const email = record.get('email');
          const facebook = record.get('facebook');
          const twitter = record.get('twitter');
          const linkedin = record.get('linkedin');
          const instagram = record.get('instagram');
          const github = record.get('github');
          const aboutImage = record.get('aboutImage');
          const bannerImage = record.get('bannerImage');

          // Add the mapped record to the resumeContent array
          personalContent.push({
            name,
            quote,
            status,
            resume,
            btnText,
            phone,
            email,
            facebook,
            twitter,
            linkedin,
            instagram,
            github,
            aboutImage,
            bannerImage
          });
        });

        // To fetch the next page of records, call `fetchNextPage`.
        // If there are more records, `page` will get called again.
        // If there are no more records, `done` will get called.
        fetchNextPage();
      }
    );

  return personalContent;
}

export default fetchPersonalContent;
