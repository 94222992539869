import Airtable from 'airtable';
require('dotenv').config();

const base = new Airtable({ apiKey: process.env.REACT_APP_AIRTABLE_TOKEN }).base('appohOKjwK2KPwIQZ');

async function fetchSkillContent() {
  const skillContent = await new Promise((resolve, reject) => {
    const skillMap = {};
    base('skills')
      .select({
        // Add your filters and sorting options here
        sort: [{ field: 'category', direction: 'asc' }, { field: 'skillID', direction: 'asc' }],
      })
      .eachPage(
        function page(records, fetchNextPage) {
          // This function (`page`) will get called for each page of records.
          records.forEach(function (record) {
            // Map the Airtable record to the required format
            const name = record.get('name');
            const category = record.get('category');
            const level = record.get('level');

            // Add the mapped record to the skillMap
            if (!skillMap[category]) {
              skillMap[category] = {
                skillName: category,
                skills: [],
              };
            }

            skillMap[category].skills.push({
              name,
              level,
            });
          });

          // To fetch the next page of records, call `fetchNextPage`.
          // If there are more records, `page` will get called again.
          // If there are no more records, `done` will get called.
          fetchNextPage();
        },
        function done(err) {
          if (err) {
            reject(err);
          } else {
            // Convert the skillMap object to an array
            const skillContent = Object.values(skillMap);
            resolve(skillContent);
          }
        }
      );
  });

  return skillContent;
}

export default fetchSkillContent;
