import React, { useState, useEffect } from 'react';
import fetchPersonalContent from './AirtableDataPersonal';
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaGithub,
} from "react-icons/fa";

const Social = () => {
  const [personalContent, setPersonalContent] = useState([]);
  useEffect(() => {
    async function getPersonalContent() {
      const content = await fetchPersonalContent();
      setPersonalContent(content);
    }
    getPersonalContent();
  }, []);
  return (
    <div className="nav social-icons justify-content-center">
      {personalContent.length > 0 &&
        personalContent[0].facebook && (
          <a href={`${personalContent[0].facebook}`} rel="noreferrer" target="_blank">
            <FaFacebookF />
          </a>
        )}
      {personalContent.length > 0 &&
        personalContent[0].linkedin && (
          <a href={`${personalContent[0].linkedin}`} rel="noreferrer" target="_blank">
            <FaLinkedinIn />
          </a>
        )}
      {personalContent.length > 0 &&
        personalContent[0].instagram && (
          <a href={`${personalContent[0].instagram}`} rel="noreferrer" target="_blank">
            <FaInstagram />
          </a>
        )}
      {personalContent.length > 0 &&
        personalContent[0].twitter && (
          <a href={`${personalContent[0].twitter}`} rel="noreferrer" target="_blank">
            <FaTwitter />
          </a>
        )}
      {personalContent.length > 0 &&
        personalContent[0].github && (
          <a href={`${personalContent[0].github}`} rel="noreferrer" target="_blank">
            <FaGithub />
          </a>
        )}
    </div>
  );
};

export default Social;
