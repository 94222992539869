import React, { useState, useEffect } from "react";
import Fade from "react-reveal/Fade";
import fetchServiceContent from '../AirtableDataService';

export default function Service() {
  const [serviceContent, setServiceContent] = useState([]);
  useEffect(() => {
    async function getEducationContent() {
      const content = await fetchServiceContent();
      setServiceContent(content);
    }
    getEducationContent();
  }, []);

  return (
    <>
      <div className="row">
        {serviceContent.map((val, i) => (
          <div className="col-md-6 col-lg-4 my-3" key={i}>
            <div className="feature-box-01">
              <Fade bottom>
                <div className="icon">
                  <i className={`icon ${val.icon}`}></i>
                </div>
                <div className="feature-content">
                  <h5>{val.title}</h5>
                  <p>{val.descriptions}</p>
                </div>
              </Fade>
            </div>
            {/* End .feature-box-01 */}
          </div>
        ))}
      </div>
    </>
  );
}
