import Airtable from 'airtable';
require('dotenv').config()

const base = new Airtable({ apiKey: process.env.REACT_APP_AIRTABLE_TOKEN }).base('appohOKjwK2KPwIQZ');

async function fetchServiceContent() {
  const serviceContent = [];

  await base('service')
    .select({
      // Add your filters and sorting options here
      sort: [{ field: 'serviceID', direction: 'asc' }],
    })
    .eachPage(
      function page(records, fetchNextPage) {
        // This function (`page`) will get called for each page of records.
        records.forEach(function (record) {
          // Map the Airtable record to the required format
          const serviceID = record.get('serviceID');
          const icon = record.get('icon');
          const title = `${record.get('title')}`;
          const descriptions = record.get('descriptions');

          // Add the mapped record to the resumeContent array
          serviceContent.push({
            serviceID,
            icon,
            title,
            descriptions,
          });
        });

        // To fetch the next page of records, call `fetchNextPage`.
        // If there are more records, `page` will get called again.
        // If there are no more records, `done` will get called.
        fetchNextPage();
      }
    );

  return serviceContent;
}

export default fetchServiceContent;
