import React, { useState, useEffect } from "react";
import TextLoop from "react-text-loop";
import Fade from "react-reveal/Fade";
import fetchPersonalContent from '../AirtableDataPersonal';

const Slider = () => {
  const [personalContent, setPersonalContent] = useState([]);
  useEffect(() => {
    async function getPersonalContent() {
      const content = await fetchPersonalContent();
      setPersonalContent(content);
    }
    getPersonalContent();
  }, []);

  return (
    <>
      {/*  Home Banner */}
      <section id="home" className="home-banner">
        <div className="hb-top-fixed d-flex">
          {personalContent.map((val, i) => (
              <div className="hb-info" key={i}>
                <a>{Date().toLocaleString()}</a>
                <a href="mailto:{`${val.email}`}">
                  {val.email}
                </a>
              </div>
          ))}
        </div>
        {/* End hp-top-fixed */}

        <div className="container">
          <div className="row full-screen align-items-center">
            <div className="col-lg-7">
            {personalContent.map((val, i) => (
              <div className="type-box" key={i}>
                <Fade bottom>
                  <h6>Hello, My name is</h6>
                  <h1 className="font-alt">{val.name}</h1>
                  <TextLoop>
                    {val.status.map(s => (
                      <p className="loop-text lead" key={i}>{s}</p>
                    ))}
                  </TextLoop>{" "}
                  <p className="desc">{val.quote}</p>
                  <div className="mt-4">
                    <a
                      className="px-btn px-btn-white"
                      href={`${val.resume[0].url}`}
                      download
                    >
                      {val.btnText}
                    </a>
                  </div>
                </Fade>
              </div>
            ))}
            </div>
          </div>
        </div>
        {/* End Container*/}
        {personalContent.map((val, i) => (
          <div
            className="hb-me"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + val.bannerImage[0].url
              })`,
            }}
            key={i}
          >
          </div>
        ))}
      </section>
      {/* End Home Banner  */}
    </>
  );
};

export default Slider;
