import Airtable from 'airtable';
require('dotenv').config()

const base = new Airtable({ apiKey: process.env.REACT_APP_AIRTABLE_TOKEN }).base('appohOKjwK2KPwIQZ');

async function fetchIntroductionContent() {
  const introductionContent = [];

  await base('introduction')
    .select({
      // Add your filters and sorting options here
      sort: [{ field: 'paragraphID', direction: 'asc' }],
    })
    .eachPage(
      function page(records, fetchNextPage) {
        // This function (`page`) will get called for each page of records.
        records.forEach(function (record) {
          // Map the Airtable record to the required format
          const paragraphID = record.get('paragraphID');
          const description = record.get('description');

          // Add the mapped record to the resumeContent array
          introductionContent.push({
            paragraphID,
            description,
          });
        });

        // To fetch the next page of records, call `fetchNextPage`.
        // If there are more records, `page` will get called again.
        // If there are no more records, `done` will get called.
        fetchNextPage();
      }
    );

  return introductionContent;
}

export default fetchIntroductionContent;
