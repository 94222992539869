import React, { useState, useEffect } from 'react';
import Fade from "react-reveal/Fade";
import fetchSkillContent from '../AirtableDataSkill';

const Awards = () => {
  const [skillContent, setSkillContent] = useState([]);
  useEffect(() => {
    async function getSkillContent() {
      const content = await fetchSkillContent();
      setSkillContent(content);
    }
    getSkillContent();
  }, []);
  return (
    <>
      <div className="row">
        {skillContent.map((val, i) => (
          <div className="col-lg-4 m-15px-tb" key={i}>
            <div className="feature-box-02 d-flex align-items-center">
              <Fade bottom>
                <div className="media-body">
                  <h6>{val.skillName}</h6>
                  {val.skills.map((skill, i) => (
                    <div className="skill-lt" key={i}>
                    <h6>{skill.name} - {skill.level}</h6>
                    <div className="skill-bar">
                      <div
                        className="skill-bar-in"
                        style={{ width: ((skill.level/5)*100) + "%" }}
                      ></div>
                    </div>
                  </div>
                  ))}
                </div>
              </Fade>
            </div>
          </div>
          // End .col
        ))}
      </div>
      <div className="row align-items-center">
        <div className="col-md-6 my-2"></div>
        <div className="col-md-6 my-2 text-center text-md-end">
          <span className="skill-level">1 - Basic </span>
          <span className="skill-level">2 - Novice </span>
          <span className="skill-level">3 - Intermediate </span>
          <span className="skill-level">4 - Advanced </span>
          <span className="skill-level">5 - Expert </span>
        </div>
      </div>
      {/* End .row */}
    </>
  );
};

export default Awards;
