import React from "react";
import { useForm } from "react-hook-form";
import Fade from "react-reveal/Fade";
import emailjs from 'emailjs-com';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
require('dotenv').config()

const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data, e) => {
    e.preventDefault(); // Prevents default refresh by the browser
    if (data.email!==''){
      emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICEID, process.env.REACT_APP_EMAILJS_TEMPLATEID, e.target, process.env.REACT_APP_EMAILJS_USERID)
      .then((result) => {
          console.log(result.text);
          Notify.success('Message sent successfully!');
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
          Notify.failure('Error sending message!');
      });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-6">
            <Fade bottom>
              <div className="form-group mb-3">
                <input
                  type="text"
                  className="form-control theme-light"
                  placeholder="Full name"
                  {...register("name", { required: true })}
                />
                {errors.name && errors.name.type === "required" && (
                  <span className="invalid-feedback">Name is required</span>
                )}
              </div>
            </Fade>
          </div>
          {/* End .col-6 */}

          <div className="col-md-6">
            <Fade bottom>
              <div className="form-group mb-3">
                <input
                  type="email"
                  className="form-control theme-light"
                  placeholder="Email address"
                  {...register(
                    "email",
                    {
                      required: "Email is Required",
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "Entered value does not match email format",
                      },
                    },
                    { required: true }
                  )}
                />
                {errors.email && (
                  <span className="invalid-feedback">
                    {errors.email.message}
                  </span>
                )}
              </div>
            </Fade>
          </div>
          {/* End .col-6 */}

          <div className="col-12">
            <Fade bottom>
              <div className="form-group mb-3">
                <input
                  type="text"
                  className="form-control theme-light"
                  placeholder="Subject"
                  {...register("subject", { required: true })}
                />
                {errors.subject && (
                  <span className="invalid-feedback">Subject is required.</span>
                )}
              </div>
            </Fade>
          </div>
          {/* End .col-12 */}

          <div className="col-12">
            <Fade bottom>
              <div className="form-group mb-3">
                <textarea
                  rows="4"
                  className="form-control theme-light"
                  placeholder="Type comment"
                  {...register("comment", { required: true })}
                ></textarea>
                {errors.comment && (
                  <span className="invalid-feedback">Comment is required.</span>
                )}
              </div>
            </Fade>
          </div>
          {/* End .col-12 */}

          <div className="col-12">
            <Fade bottom>
              <div className="btn-bar">
                <button className="px-btn px-btn-white">Send Message</button>
              </div>
            </Fade>
          </div>
          {/* End .col-12 */}
        </div>
      </form>
    </>
  );
};

export default Contact;
