import React, { useState, useEffect } from "react";
import axios from 'axios';
import Modal from "react-modal";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Fade from "react-reveal/Fade";


Modal.setAppElement("#root");

const mediumURL = "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@dataalgo";
const Blogs = () => {
  const [isSliderScrolling, setIsSliderScrolling] = useState(false);
  const settings = {
    dots: true,
    arrow: false,
    infinite: true,
    speed: 900,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: false,
    margin: 20,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [modalId, setModalId] = useState(false);
  const handleClose = () => setModalId("");
  const [blog, setBlog] = useState();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    axios.get(mediumURL).then(response => {
      setBlog(response.data.items);
      setLoading(false);
      console.log(response.data.items);
    });
  }, []);


  if (isLoading) {
    return <div className="row">Loading...</div>;
  }
  return (
    <>
      <div className="row testimonial-wrapper">
      <Slider 
        {...settings} 
        beforeChange={() => setIsSliderScrolling(true)}
        afterChange={() => setIsSliderScrolling(false)}>
        {blog.map((val, i) => (
          <div key={val.title} className="col-md-6 m-15px-tb">
            <div className="blog-grid" onClick={() => !isSliderScrolling && setModalId(`modal${i}`)}>
              <div className="blog-img">
                <Fade>
                  <img src={val.thumbnail} alt="blog post"></img>
                </Fade>
              </div>
              <div className="blog-info">
                <Fade bottom>
                  <div className="meta">{val.pubDate}</div>
                  <h6>
                    <a href="#">
                      {val.title}
                    </a>
                  </h6>
                </Fade>
              </div>
            </div>
          </div>
        ))}
        {/* End .col for blog-1 */}
        </Slider>
      </div>
      {/* End .row */}

      {blog.map((val, i) => (
        <Modal
          key={val.title}
          isOpen={modalId === `modal${i}`}
          onRequestClose={handleClose}
          contentLabel="My dialog"
          className="custom-modal"
          overlayClassName="custom-overlay"
          closeTimeoutMS={500}
        >
          <div>
            <button className="close-modal" onClick={handleClose}>
              <img src="/img/cancel.svg" alt="close icon" />
            </button>
            {/* End close icon */}
            <div className="box_inner">
              <div className="scrollable">
                <div className="blog-grid">
                  {/* End blog-img */}
                  <article className="article">
                    <div className="article-title">
                      <h2>
                        {val.title}
                      </h2>
                      <div className="media">
                        <div className="media-body">
                          <label>Suman Raj Sharma</label>
                          <span>{val.pubDate}</span>
                        </div>
                      </div>
                    </div>
                    {/* End .article-title */}

                    <div className="article-content" dangerouslySetInnerHTML={{ __html: val.description }}>
                    </div>
                    {/* End article content */}
                    <ul className="nav tag-cloud">
                      {/* {blog.categories.map((tag, i) => (
                          <li href="#">{tag}</li>
                      ))} */}
                    </ul>
                    {/* End tag */}
                  </article>
                  {/* End Article */}
                  {val.link !== '' ? <a className="px-btn px-btn-white" href={val.link} target="_blank">Read on Medium</a> : null}
                </div>
              </div>
            </div>
          </div>
          {/* End modal box  */}
        </Modal>
      ))}
    </>
  );
};

export default Blogs;
