import Airtable from 'airtable';
require('dotenv').config()

const base = new Airtable({ apiKey: process.env.REACT_APP_AIRTABLE_TOKEN }).base('appohOKjwK2KPwIQZ');

async function fetchProjectContent() {
  const projectContent = [];

  await base('projects')
    .select({
      // Add your filters and sorting options here
      sort: [{ field: 'date', direction: 'desc' }],
    })
    .eachPage(
      function page(records, fetchNextPage) {
        // This function (`page`) will get called for each page of records.
        records.forEach(function (record) {
          // Map the Airtable record to the required format
          
          const projectID = record.get('projectID');
          const title = record.get('title');
          const imageUrl = record.get('imageUrl');
          const date = record.get('date');
          const description = record.get('description');
          const tags = record.get('tags');
          const projectUrl = record.get('projectUrl');

          // Add the mapped record to the resumeContent array
          projectContent.push({
            projectID,
            title,
            imageUrl,
            date,
            description,
            tags,
            projectUrl
          });
        });

        // To fetch the next page of records, call `fetchNextPage`.
        // If there are more records, `page` will get called again.
        // If there are no more records, `done` will get called.
        fetchNextPage();
      }
    );

  return projectContent;
}

export default fetchProjectContent;
