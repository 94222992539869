import Airtable from 'airtable';
require('dotenv').config()

const base = new Airtable({ apiKey: process.env.REACT_APP_AIRTABLE_TOKEN }).base('appohOKjwK2KPwIQZ');

async function fetchExperienceContent() {
  const experienceContent = [];

  await base('experience')
    .select({
      // Add your filters and sorting options here
      sort: [{ field: 'jobID', direction: 'asc' }],
    })
    .eachPage(
      function page(records, fetchNextPage) {
        // This function (`page`) will get called for each page of records.
        records.forEach(function (record) {
          // Map the Airtable record to the required format
          const jobPosition = record.get('jobPosition');
          const jobType = record.get('jobType');
          const jobDuration = `${record.get('jobDuration')}`;
          const timeDuration = record.get('timeDuration');
          const companyName = record.get('companyName');
          const jobDescription = record.get('jobDescription');

          // Add the mapped record to the resumeContent array
          experienceContent.push({
            jobPosition,
            jobType,
            jobDuration,
            timeDuration,
            companyName,
            jobDescription,
          });
        });

        // To fetch the next page of records, call `fetchNextPage`.
        // If there are more records, `page` will get called again.
        // If there are no more records, `done` will get called.
        fetchNextPage();
      }
    );

  return experienceContent;
}

export default fetchExperienceContent;
