import React from "react";
import Routes from "./router/Routes";
import ScrollToTop from "./components/ScrollToTop";
import AnimatedCursor from "react-animated-cursor";
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';

const TRACKING_ID = "UA-168754191-1"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const history = createBrowserHistory();
// Initialize google analytics page view tracking
history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

const App = () => {
  return (
    <Router history={history}>
      <ScrollToTop />
        <Routes />
        <AnimatedCursor
          innerSize={12}
          outerSize={50}
          color="255, 147, 1"
          outerAlpha={0.2}
          innerScale={0.7}
          outerScale={1}
        />
    </Router>
  );
};

export default App;
