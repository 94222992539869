import React, { useState, useEffect } from 'react';
import Social from "../Social";
import Services from "../service/ServiceAnimation";
import Awards from "../award/AwardsAnimation";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import TextLoop from "react-text-loop";
import fetchIntroductionContent from '../AirtableDataIntroduction';
import fetchPersonalContent from '../AirtableDataPersonal';

const About = () => {
  const [introductionContent, setIntroductionContent] = useState([]);
  const [personalContent, setPersonalContent] = useState([]);
  useEffect(() => {
    async function getIntroductionContent() {
      const content = await fetchIntroductionContent();
        setIntroductionContent(content);
    }
    async function getPersonalContent() {
      const content = await fetchPersonalContent();
      setPersonalContent(content);
    }
    getIntroductionContent();
    getPersonalContent();
  }, []);

  return (
    <>
      <section id="about" className="section theme-light dark-bg">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-6 col-lg-4">
            {personalContent.map((val, i) => (
              <div className="about-me" key={i}>
                <div className="img">
                  <div className="img-in">
                    <img src={`${val.aboutImage[0].url}`} alt="about" />
                  </div>
                  <Zoom>
                    <Social />
                  </Zoom>
                  {/* End social icon */}
                </div>
                {/* End img */}
                <div className="info">
                  <Fade bottom>
                  <TextLoop>
                    {val.status.map(s => (
                      <p className="loop-text lead" key={i}>{s}</p>
                    ))}
                  </TextLoop>
                    <h3>{val.name}</h3>
                  </Fade>
                </div>
                {/* End info */}
              </div>
            ))}
              {/* End about-me */}
            </div>
            {/* End col */}

            <div className="col-lg-7 ml-auto">
              <div className="about-info">
                <div className="title">
                  <h3>About Me.</h3>
                </div>
                {introductionContent.map((val, i) => (
                <div className="about-text" key={i}>
                  <Fade bottom>
                    <p>
                      {val.description}
                    </p>
                  </Fade>
                </div>
                ))}
              </div>
            </div>
            {/* End col */}
          </div>

          {/* separated */}

          <div
            className="separated"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "img/border-dark.png"
              })`,
            }}
          ></div>

          {/* End separated */}
          <div className="title">
            <h3>Areas of Interest.</h3>
          </div>

          <Services />

          {/* End .row */}

          {/* separated */}
          <div
            className="separated"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "img/border-dark.png"
              })`,
            }}
          ></div>
          {/* End separated */}

          <div className="title">
            <h3>Skills Expertise.</h3>
          </div>

          <Awards />
          {/* End Awards */}
        </div>
      </section>
    </>
  );
};

export default About;
