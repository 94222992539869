import Airtable from 'airtable';
require('dotenv').config()

const base = new Airtable({ apiKey: process.env.REACT_APP_AIRTABLE_TOKEN }).base('appohOKjwK2KPwIQZ');

async function fetchEducationContent() {
  const educationContent = [];

  await base('education')
    .select({
      // Add your filters and sorting options here
      sort: [{ field: 'educationID', direction: 'desc' }],
    })
    .eachPage(
      function page(records, fetchNextPage) {
        // This function (`page`) will get called for each page of records.
        records.forEach(function (record) {
          // Map the Airtable record to the required format
          const educationID = record.get('educationID');
          const imageName = record.get('imageName');
          const passingYear = `${record.get('passingYear')}`;
          const degreeTitle = record.get('degreeTitle');
          const instituteName = record.get('instituteName');
          const majors = record.get('majors');
          const imageUrl = record.get('image') ? record.get('image')[0].url : null;

          // Add the mapped record to the resumeContent array
          educationContent.push({
            educationID,
            imageName,
            passingYear,
            degreeTitle,
            instituteName,
            majors,
            imageUrl,
          });
        });

        // To fetch the next page of records, call `fetchNextPage`.
        // If there are more records, `page` will get called again.
        // If there are no more records, `done` will get called.
        fetchNextPage();
      }
    );

  return educationContent;
}

export default fetchEducationContent;
