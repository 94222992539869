import React from "react";
import Fade from "react-reveal/Fade";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaGithub,
} from "react-icons/fa";

const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/suman.r.sharma" },
  { Social: <FaTwitter />, link: "https://www.twitter.com/sumanrajsharma" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/sumanrajsharma" },
  { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/in/sumanrajsharma" },
  { Social: <FaGithub/>, link: "https://www.github.com/sumanrajsharma" },
];

const Footer = () => {
  return (
    <>
      <div className="row align-items-center">
        <div className="col-md-6 my-2">
          <div className="nav justify-content-center justify-content-md-start">
            <Fade bottom>
              {SocialShare.map((val, i) => (
                <a
                  key={i}
                  href={`${val.link}`}
                  rel="noreferrer"
                  target="_blank"
                >
                  {val.Social}
                </a>
              ))}{" "}
            </Fade>
          </div>
          {/* End .nav */}
        </div>
        {/* End .col */}

        <div className="col-md-6 my-2 text-center text-md-end">
          <Fade bottom>
            <p>
              © {new Date().getFullYear()} Copyright{" "}
              {/* <a
                href="https://sumansharma.tech"
                target="_blank"
                rel="noreferrer"
              >
                Suman Raj Sharma
              </a>{" "} */}
              All Right Reserved
            </p>{" "}
          </Fade>
        </div>
        {/* End .col */}
      </div>
      {/* End .row */}
    </>
  );
};

export default Footer;
